import { useEffect } from "react";

declare global {
  interface Window {
    googleTranslateElementInit: () => void;
    google?: {
      translate?: {
        TranslateElement: new (options: object, containerId: string) => void;
      };
    };
  }
}

const GoogleTranslate = () => {
  useEffect(() => {
    const addGoogleTranslateScript = () => {
      // Prevent multiple instances
      if (document.getElementById("google-translate-script")) return;

      const script = document.createElement("script");
      script.id = "google-translate-script";
      script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);

      window.googleTranslateElementInit = () => {
        if (window.google?.translate) {
          new window.google.translate.TranslateElement(
            {
              pageLanguage: "nl",
              includedLanguages: "en,nl",
              autoDisplay: false,
            },
            "google_translate_element"
          );
        }
      };
    };

    addGoogleTranslateScript();
    // ✅ Hide unwanted <td width="1"> after iframe loads
    const observer = new MutationObserver(() => {
      const iframe = document.querySelector("iframe.skiptranslate");
      if (iframe) {
        const iframeDocument = (iframe as HTMLIFrameElement).contentDocument;
        if (iframeDocument) {
          const unwantedTDs = iframeDocument.querySelectorAll('td[width="1"]');
          unwantedTDs.forEach((td, index) => {
            if (index < 2) {
              (td as HTMLElement).style.display = "none";
            }
          });

          // Select only <td> elements that have both width="1" and id="options"
          const unwantedTDsWithOptions = iframeDocument.querySelectorAll(
            'td[width="1"]#options'
          );
          unwantedTDsWithOptions.forEach((td) => {
            (td as HTMLElement).style.display = "none";
          });
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <div
      id="google_translate_element"
      className="mt-4 flex justify-end items-center w-full pr-4"
    ></div>
  );
};

export default GoogleTranslate;
