import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { GlobalContextProvider } from "./contexts/GlobalContext";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import { PlaceNewAd } from "./components/PlaceNewAd";
import Home from "./components/Home";
import MyHorses from "./components/MyHorses";
import "./i18n";
import GoogleTranslate from "./components/GoogleTranslate";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <GoogleTranslate />
      <Router>
        <Routes>
          {/* <Route path="/" element={<App />}></Route> */}
          <Route path="/" element={<Login />}></Route>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/sell" element={<PlaceNewAd />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/my-horses" element={<MyHorses />}></Route>
          <Route path="/edit" element={<PlaceNewAd />}></Route>
          {/* <Route
            path="/:projectId/raise-ticket"
            element={<NewTicket />}
          ></Route> */}
          {/* <Route path="/:projectId/tickets" element={<Tickets />}></Route> */}
          {/* <Route
            path="/progress/:projectId/:ticketId"
            element={<TicketProgress />}
          ></Route> */}
          <Route path="/*" element={<Navigate to="/" />}></Route>
        </Routes>
      </Router>
    </GlobalContextProvider>
  </React.StrictMode>
);
